import * as ss from 'superstruct'

import type { Translate } from '@/utils/types/common'

import { statusUsuario } from '..'
import { DBRow, FileRecord } from '../common'
import type { TipoCredencial } from './auth'

// --------------------------------------------------------------------------------------

export const tipoStatusFicha = [
  'APROVADA',
  'APROVADA_QUEBRA',
  'PENDENTE',
  'RECUSADA',
  'RECUSADA_DEFINITIVO',
] as const

export const TipoStatusFicha = ss.enums(tipoStatusFicha)
export type TipoStatusFicha = ss.Infer<typeof TipoStatusFicha>

export const statusFichaColor = (s: TipoStatusFicha, textOnly?: boolean) => {
  let str: string

  switch (s) {
    case 'APROVADA':
    case 'APROVADA_QUEBRA':
      str = 'text-success-300 border-success-300 bg-success-100'
      break

    case 'RECUSADA':
    case 'RECUSADA_DEFINITIVO':
      str = 'text-danger-400 border-danger-200 bg-danger-100'
      break

    case 'PENDENTE':
      str = 'text-warning-400 border-warning-200 bg-warning-100'
      break

    default:
      str = 'text-light-gray-500 border-light-gray-500'
      break
  }

  if (textOnly) {
    const m = str?.match(/text-\w+-\d{3}/)?.[0]
    str = m || str
  }

  return str
}

export const statusFichaDisplay = (t: Translate, c?: TipoCredencial) => {
  return {
    APROVADA: t('status.approved'),
    APROVADA_QUEBRA:
      c !== 'FINANCIADOR'
        ? t('status.approved_with_break')
        : t('status.approved'),
    PENDENTE: t('status.pending'),
    RECUSADA: t('status.refused'),
    RECUSADA_DEFINITIVO:
      c !== 'FINANCIADOR' ? t('status.final_refusal') : t('status.refused'),
  }
}

/*export const statusFichaOptions = tipoStatusFicha.map((v) => ({
  label: statusFichaDisplay()[v],
  value: v,
}))*/

// --------------------------------------------------------------------------------------

export const TipoFunding = ss.enums([
  'BANCO_COMERCIAL',
  'FINANCEIRA',
  'SECURITIZADORA',
  'FIDC',
  'FINTECH',
])

export type TipoFunding = ss.Infer<typeof TipoFunding>

// --------------------------------------------------------------------------------------

export const TipoClienteFunding = ss.nullable(
  ss.enums(['AMBAS', 'PESSOA_JURIDICA', 'PESSOA_FISICA'])
)
export type TipoClienteFunding = ss.Infer<typeof TipoClienteFunding>

// --------------------------------------------------------------------------------------

export const TipoFundingRestricoesPJ = ss.enums([
  'ASSOCIACAO',
  'CONDOMINIO',
  'COOPERATIVA',
  'ENTIDADE_RELIGIOSA',
])

export type TipoFundingRestricoesPJ = ss.Infer<typeof TipoFundingRestricoesPJ>

// --------------------------------------------------------------------------------------

export const TipoFundingOperacao = ss.enums([
  'SERVICOS_INTALACAO',
  'FINANCIAMENTO_EQUIPAMENTOS',
  'PAINEL',
  'INVERSOR',
  'BATERIA',
  'CARREGADOR_CARRO_ELETRICO',
  'HOME_EQUITY',
  'CARRO_ELETRICO',
])

export type TipoFundingOperacao = ss.Infer<typeof TipoFundingOperacao>

// --------------------------------------------------------------------------------------

export const Retorno = ss.assign(
  DBRow,
  ss.object({
    tabela: ss.nullable(ss.optional(ss.string())),
    taxa: ss.optional(ss.nullable(ss.number())),
    fundingId: ss.optional(ss.nullable(ss.string())),
    funding: ss.nullable(ss.optional(ss.any())),
  })
)

export type Retorno = ss.Infer<typeof Retorno>

// --------------------------------------------------------------------------------------

export const Plus = ss.assign(
  DBRow,
  ss.object({
    valorInicial: ss.nullable(ss.optional(ss.number())),
    valorFinal: ss.nullable(ss.optional(ss.number())),
    taxa: ss.optional(ss.nullable(ss.number())),
    fundingId: ss.optional(ss.nullable(ss.string())),
    funding: ss.nullable(ss.optional(ss.any())),
  })
)

export type Plus = ss.Infer<typeof Plus>

// --------------------------------------------------------------------------------------

export const ContatoFunding = ss.assign(
  DBRow,
  ss.object({
    principal: ss.nullable(ss.optional(ss.boolean())),
    nome: ss.nullable(ss.optional(ss.string())),
    telefone: ss.nullable(ss.optional(ss.string())),
    email: ss.optional(ss.nullable(ss.string())),
    fundingId: ss.optional(ss.nullable(ss.string())),
    funding: ss.nullable(ss.optional(ss.any())),
  })
)

export type ContatoFunding = ss.Infer<typeof ContatoFunding>

// --------------------------------------------------------------------------------------

export const Carteira = ss.assign(
  DBRow,
  ss.object({
    agencia: ss.optional(ss.string()),
    apelido: ss.optional(ss.string()),
    banco: ss.string(),
    cnpj: ss.optional(ss.string()),
    conta: ss.optional(ss.string()),
    gerenciada: ss.optional(ss.boolean()),
    value: ss.optional(ss.string()),
  })
)
export type Carteira = ss.Infer<typeof Carteira>

// --------------------------------------------------------------------------------------

export const Funding = ss.assign(
  DBRow,
  ss.object({
    logoId: ss.nullable(ss.optional(ss.string())),
    credencialId: ss.nullable(ss.optional(ss.string())),
    enderecoId: ss.nullable(ss.optional(ss.string())),
    carteiraId: ss.nullable(ss.optional(ss.string())),
    carteira: ss.nullable(Carteira),
    razaoSocial: ss.nullable(ss.optional(ss.string())),
    apelido: ss.optional(ss.nullable(ss.string())),
    name: ss.optional(ss.nullable(ss.string())),
    tipo: ss.optional(ss.nullable(TipoFunding)),
    cnpj: ss.optional(ss.nullable(ss.string())),
    status: ss.optional(ss.nullable(ss.enums(statusUsuario))),
    nomeFantasia: ss.optional(ss.nullable(ss.string())),

    prioridade: ss.optional(ss.nullable(ss.number())),

    taxaPadraoParcelamento: ss.nullable(ss.optional(ss.number())),
    valorMinimoProjeto: ss.nullable(ss.optional(ss.number())),
    valorMaximoProjeto: ss.nullable(ss.optional(ss.number())),
    carenciaMaxima: ss.nullable(ss.optional(ss.number())),
    compromentimentoRendaMaximo: ss.nullable(ss.optional(ss.number())),
    tempoMaximoFinaciamento: ss.nullable(ss.optional(ss.number())),
    restricoesPJ: ss.array(TipoFundingRestricoesPJ),
    tipoCliente: TipoClienteFunding,
    tipoOperacao: ss.array(TipoFundingOperacao),

    contatos: ss.array(ContatoFunding),
    retornos: ss.nullable(ss.optional(ss.array(Retorno))),
    plus: ss.nullable(ss.optional(ss.array(Plus))),
    fichas: ss.nullable(ss.optional(ss.array(ss.any()))),
    logo: ss.nullable(ss.optional(FileRecord)),
    credencial: ss.nullable(ss.optional(ss.any())),

    endereco: ss.nullable(ss.optional(ss.any())),
    servicos: ss.nullable(ss.optional(ss.array(ss.any()))),
    regions: ss.optional(ss.nullable(ss.array(ss.any()))),
    loanTypes: ss.optional(ss.nullable(ss.array(ss.any()))),
    projectTypes: ss.optional(ss.nullable(ss.array(ss.any()))),

    disableAppSubmission: ss.boolean(),
    disableDocSubmission: ss.boolean(),
    documentationEmails: ss.optional(ss.array(ss.string())),
    documentationPassword: ss.string(),
    generateCcb: ss.boolean(),
  })
)

export type Funding = ss.Infer<typeof Funding>

// --------------------------------------------------------------------------------------

export const CondicoesEnvioFicha = ss.assign(
  DBRow,
  ss.object({
    taxaParcelamento: ss.nullable(ss.optional(ss.number())),
    valorMinimoProjeto: ss.nullable(ss.optional(ss.number())),
    valorMaximoProjeto: ss.nullable(ss.optional(ss.number())),
    carenciaMaxima: ss.nullable(ss.optional(ss.number())),
    tempoMaximoFinaciamento: ss.nullable(ss.optional(ss.number())),
    tipoCliente: ss.nullable(ss.optional(TipoClienteFunding)),
  })
)

export type CondicoesEnvioFicha = ss.Infer<typeof CondicoesEnvioFicha>

// --------------------------------------------------------------------------------------

// --------------------------------------------------------------------------------------
export const StatusEnvioFinanciador = ss.enums([
  'PENDENTE',
  'ELEGIVEL',
  'NAO_ELEGIVEL',
  'FALHA',
  'INDISPONIVEL',
])

export const EnvioFinanciador = ss.assign(
  DBRow,
  ss.object({
    fichaId: ss.nullable(ss.optional(ss.string())),
    codigo: ss.nullable(ss.optional(ss.string())),
    status: ss.nullable(ss.optional(StatusEnvioFinanciador)),
    mensagem: ss.nullable(ss.optional(ss.string())),
    idIntegracao: ss.nullable(ss.optional(ss.string())),
    applicationHistory: ss.nullable(
      ss.optional(
        ss.array(
          ss.object({
            id: ss.string(),
            message: ss.string(),
          })
        )
      )
    ),
  })
)

export type EnvioFinanciador = ss.Infer<typeof EnvioFinanciador>

// --------------------------------------------------------------------------------------

export const Ficha = ss.assign(
  DBRow,
  ss.object({
    propostaId: ss.optional(ss.nullable(ss.string())),
    fundingId: ss.optional(ss.nullable(ss.string())),
    nome: ss.optional(ss.nullable(ss.string())),
    status: TipoStatusFicha,
    valorTotalVenda: ss.optional(ss.nullable(ss.number())),
    entrada: ss.optional(ss.nullable(ss.number())),
    descontoComercial: ss.optional(ss.nullable(ss.number())),
    valorFinanciado: ss.optional(ss.nullable(ss.number())),
    tempoFinanciado: ss.optional(ss.nullable(ss.number())),
    carencia: ss.optional(ss.nullable(ss.number())),
    parcela: ss.optional(ss.nullable(ss.number())),
    taxa: ss.optional(ss.nullable(ss.number())),
    taxaAno: ss.optional(ss.nullable(ss.number())),
    cetPeriodoMes: ss.optional(ss.nullable(ss.number())),
    cetPeriodoAno: ss.optional(ss.nullable(ss.number())),
    cetPeriodoDia: ss.optional(ss.nullable(ss.number())),
    carenciaPeriodoDia: ss.optional(ss.nullable(ss.number())),
    valorRetorno: ss.optional(ss.nullable(ss.number())),
    porcentagemRetorno: ss.optional(ss.nullable(ss.number())),
    valorPlus: ss.optional(ss.nullable(ss.number())),
    porcentagemPlus: ss.optional(ss.nullable(ss.number())),
    valorSpread: ss.optional(ss.nullable(ss.number())),
    porcentagemSpread: ss.optional(ss.nullable(ss.number())),
    editada: ss.optional(ss.nullable(ss.boolean())),
    escolhidaCliente: ss.optional(ss.nullable(ss.boolean())),
    comSeguro: ss.optional(ss.boolean()),
    condicoesDeEnvio: ss.nullable(ss.optional(CondicoesEnvioFicha)),
    proposta: ss.optional(ss.nullable(ss.any())),
    funding: ss.optional(ss.nullable(Funding)),
    entradaIntegrador: ss.optional(ss.nullable(ss.number())),
    entradaDistribuidor: ss.optional(ss.nullable(ss.number())),
    financierStatus: ss.optional(ss.nullable(ss.string())),
    code: ss.optional(ss.nullable(ss.string())),
    notes: ss.optional(ss.nullable(ss.string())),
    application: ss.optional(ss.nullable(ss.string())),
    contractDate: ss.optional(ss.nullable(ss.string())),
    firstInstallmentDate: ss.optional(ss.nullable(ss.string())),
    otherCost: ss.optional(ss.nullable(ss.number())),
    iof: ss.optional(ss.nullable(ss.number())),
    iofRate: ss.optional(ss.nullable(ss.number())),
    valorLiberado: ss.optional(ss.nullable(ss.number())),
  })
)

export type Ficha = ss.Infer<typeof Ficha>
